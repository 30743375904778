import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ModalUnit from './ModalUnit';

/**
 * Modal split into Modal and ModalUnit
 * so that it can be checked whether the user
 * clicked outside (which should close the modal)
 */
class Modal extends React.Component {
  render() {
    if (!this.props.show) {
      return null;
    }

    return ReactDOM.createPortal(
      <ModalUnit show={this.props.show} onClose={this.props.onClose}>
        {this.props.children}
      </ModalUnit>,
      document.body
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default Modal;
