import React from 'react';
import styled from 'styled-components';

export default (props) => (
  <SVG {...props} width="14px" height="24px" viewBox="0 0 14 24" version="1.1">
    <g id="Knowledge-Hub" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="FC-design-system-atoms-icons"
        transform="translate(-155.000000, -3212.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(0.000000, 3144.000000)">
          <g id="Group-6" transform="translate(61.000000, 55.000000)">
            <g id="SM-icons" transform="translate(39.000000, 13.000000)">
              <path
                d="M64.0864373,23.9999928 L64.0864373,13.5000179 L67.587497,13.5000179 L68.2540909,9.15658341 L64.0864373,9.15658341 L64.0864373,6.33800188 C64.0864373,5.14974177 64.6686197,3.99146511 66.5351924,3.99146511 L68.4298717,3.99146511 L68.4298717,0.293475882 C68.4298717,0.293475882 66.7104697,5.00679016e-05 65.0665985,5.00679016e-05 C61.6343862,5.00679016e-05 59.3909834,2.08035211 59.3909834,5.84627331 L59.3909834,9.15658341 L55.5758069,9.15658341 L55.5758069,13.5000179 L59.3909834,13.5000179 L59.3909834,23.9999928 L64.0864373,23.9999928 Z"
                id="FB"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

const SVG = styled.svg`
  #FC-design-system-atoms-icons {
    fill: #ffffffcc;
  }

  &:hover {
    #FC-design-system-atoms-icons {
      fill: #fff;
    }
  }
`;
