import React, { useState } from 'react';

import Facebook from './icons/Facebook';
import Instagram from './icons/Instagram';
import { Link } from 'gatsby';
import Linkedin from './icons/Linkedin';
import Medium from './icons/Medium';
import Modal from './modal/Modal';
import ModalCareerContent from './modal/ModalCareerContent';
import Twitter from './icons/Twitter';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

const Footer = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { pathname } = useLocation();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <FooterContainer className="section has-background-black" style={{ clear: 'both' }}>
      <div className="container is-medium">
        <Row>
          <Left>
            <div>
              <a
                className="level-item"
                href="https://www.instagram.com/film_chain/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <Instagram />
                </span>
              </a>
            </div>
            <div>
              <a
                className="level-item"
                href="https://www.linkedin.com/company/filmchain/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <Linkedin />
                </span>
              </a>
            </div>
            <div>
              <a
                className="level-item"
                href="https://www.facebook.com/FilmChainGroup/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <Facebook />
                </span>
              </a>
            </div>
            <div>
              <a
                className="level-item"
                href="https://filmchain.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <Medium />
                </span>
              </a>
            </div>
            <div>
              <a
                className="level-item"
                href="https://twitter.com/Film_Chain"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <Twitter />
                </span>
              </a>
            </div>
          </Left>
          <Right>
            {pathname !== '/knowledge-hub/' ? (
              <a href="/knowledge-hub">
                <p className="terms">Knowledge Hub</p>
              </a>
            ) : null}
            <p className="terms" onClick={toggleModal}>
              Careers
            </p>
            <Modal show={showModal} onClose={toggleModal}>
              <ModalCareerContent />
            </Modal>
            <a href="/privacy">
              <p className="terms">Privacy and Cookie Policy</p>
            </a>
            <a href="/legal/Data_Processing_Policy.pdf">
              <p className="terms">Data Processing Policy</p>
            </a>
            <a href="/faq">
              <p className="terms">FAQ</p>
            </a>
            <p className="cop">© {new Date().getFullYear()} FilmChain</p>
          </Right>
        </Row>
      </div>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  border-top: solid 1px #1e1e1e;
`;

const Row = styled.div`
  display: grid;
  gap: 2.5rem;

  @media screen and (min-width: 990px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;
const Left = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (min-width: 990px) {
    justify-content: flex-start;
  }
`;

const Right = styled.div`
  display: grid;
  gap: 1.5rem;
  text-align: center;

  @media screen and (min-width: 990px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    color: white;
  }
  .terms {
    color: #a7a6b3;
    font-size: 0.75rem;
    letter-spacing: 0.02rem;
    cursor: pointer;
    :hover {
      color: #fff;
    }
  }
  .cop {
    color: #a7a6b3;
    opacity: 0.75;
  }
`;
