import './all.sass';

import Banner from '../components/Banner';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import React from 'react';
import useSiteMetadata from './SiteMetadata';

const TemplateWrapper = ({ children, contactus, customTitle }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{customTitle || title}</title>
        <meta name="description" content={description} />

        <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png" />
        <link rel="manifest" href="/img/site.webmanifest" />
        <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/img/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="msapplication-config" content="/img/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://filmchain.co/" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/bigcouch/image/upload/v1596554998/business/FC-SM-thumbnail.jpg"
        />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      {/* <Navbar /> */}
      {!contactus && <Banner />}
      <div>{children}</div>

      <Footer />
    </div>
  );
};

export default TemplateWrapper;
