import React from 'react';
import styled from 'styled-components';

function ModalCareerContent() {
  return (
    <Wrapper>
      <ModalTitle className="is-size-3-desktop is-size-4-tablet">Want to work with us?</ModalTitle>
      <span className="is-size-5-desktop is-size-6-tablet">
        <Paragraph>
          We&apos;re always on the lookout for the tech & business passionate minds. Working
          remotely from Europe or North America is not an issue for us.
        </Paragraph>
        <Paragraph>
          Email us at <Link href="mailto:team@filmchain.co">team@filmchain.co</Link>
        </Paragraph>
      </span>
    </Wrapper>
  );
}

export default ModalCareerContent;

const Wrapper = styled('div')`
  padding: 2em;
  color: white;
`;

const Paragraph = styled('div')`
  padding-top: 3em;
`;

const ModalTitle = styled('h1')`
  font-weight: bold;
`;

const Link = styled('a')`
  color: inherit;
  text-decoration: underline;

  :hover {
    color: inherit;
    opacity: 0.6;
  }
`;
