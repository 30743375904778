import React from 'react';
import styled from 'styled-components';

export default (props) => (
  <SVG {...props} width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
    <g
      id="Knowledge-Hub"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.800000012"
    >
      <g
        id="FC-design-system-atoms-icons"
        transform="translate(-100.000000, -3212.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(0.000000, 3144.000000)">
          <g id="Group-6" transform="translate(61.000000, 55.000000)">
            <g id="SM-icons" transform="translate(39.000000, 13.000000)">
              <path
                d="M3.02425929,5.06720718 C4.41599487,5.06720718 5.54566136,3.91410324 5.54566136,2.5219099 C5.54566136,1.13012855 4.41608642,0.000507830482 3.02425929,0.000507830482 C1.63243217,0.000507830482 0.502857224,1.13012855 0.502857224,2.5219099 C0.502857224,3.91410324 1.63206596,5.06720718 3.02425929,5.06720718 Z M12.2797151,21 L12.2797151,14.0578474 C12.2797151,12.2297267 12.6265618,10.4625343 14.890152,10.4625343 C17.0533216,10.4625343 17.1497347,12.4239621 17.1535915,14.0259701 L17.1537422,21 L21.5028072,21 L21.5028072,13.298465 C21.5028072,9.52970569 20.6927963,6.62817758 16.2865568,6.62817758 C14.1683062,6.62817758 12.748464,7.79066565 12.1671971,8.89222552 L12.1062689,8.89222552 L12.1062689,6.97973924 L7.93065018,6.97973924 L7.93065018,21 L12.2797151,21 Z M5.20348383,21 L5.20348383,6.97973924 L0.849749707,6.97973924 L0.849749707,21 L5.20348383,21 Z"
                id="LI"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

const SVG = styled.svg`
  #FC-design-system-atoms-icons {
    fill: #ffffffcc;
  }

  &:hover {
    #FC-design-system-atoms-icons {
      fill: #fff;
    }
  }
`;
