import React from 'react';
import styled from 'styled-components';

export default (props) => (
  <SVG {...props} width="29px" height="23px" viewBox="0 0 29 23" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="FC-design-system-atoms-icons"
        transform="translate(-1647.000000, -84.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="header" transform="translate(100.000000, 64.000000)">
          <path
            d="M1575.92857,22.8571429 C1575.13095,24.0238154 1574.16667,25.017853 1573.03571,25.8392857 C1573.04762,26.0059532 1573.05357,26.2559507 1573.05357,26.5892857 C1573.05357,28.1369125 1572.82738,29.6815399 1572.375,31.2232143 C1571.92262,32.7648887 1571.23512,34.2440405 1570.3125,35.6607143 C1569.38988,37.077388 1568.29167,38.3303517 1567.01786,39.4196429 C1565.74404,40.508934 1564.20834,41.3779729 1562.41071,42.0267857 C1560.61309,42.6755985 1558.69049,43 1556.64286,43 C1553.41665,43 1550.4643,42.1369134 1547.78571,40.4107143 C1548.20238,40.4583336 1548.66666,40.4821429 1549.17857,40.4821429 C1551.85716,40.4821429 1554.24404,39.6607225 1556.33929,38.0178571 C1555.08928,37.9940475 1553.97024,37.6101228 1552.98214,36.8660714 C1551.99404,36.1220201 1551.31548,35.1726248 1550.94643,34.0178571 C1551.33929,34.0773812 1551.70238,34.1071429 1552.03571,34.1071429 C1552.54762,34.1071429 1553.05357,34.0416673 1553.55357,33.9107143 C1552.22023,33.6369034 1551.11608,32.9732196 1550.24107,31.9196429 C1549.36607,30.8660662 1548.92857,29.6428641 1548.92857,28.25 L1548.92857,28.1785714 C1549.7381,28.6309546 1550.60714,28.8749998 1551.53571,28.9107143 C1550.75,28.3869021 1550.125,27.7023852 1549.66071,26.8571429 C1549.19643,26.0119005 1548.96429,25.095243 1548.96429,24.1071429 C1548.96429,23.0595186 1549.22619,22.0892902 1549.75,21.1964286 C1551.19048,22.970247 1552.94344,24.3898756 1555.00893,25.4553571 C1557.07442,26.5208387 1559.2857,27.1130946 1561.64286,27.2321429 C1561.54762,26.7797596 1561.5,26.3392879 1561.5,25.9107143 C1561.5,24.3154682 1562.06249,22.9553628 1563.1875,21.8303571 C1564.31251,20.7053515 1565.67261,20.1428571 1567.26786,20.1428571 C1568.93453,20.1428571 1570.33928,20.7499939 1571.48214,21.9642857 C1572.77977,21.7142845 1573.99999,21.2500034 1575.14286,20.5714286 C1574.70238,21.940483 1573.85715,22.9999962 1572.60714,23.75 C1573.71429,23.6309518 1574.82142,23.3333357 1575.92857,22.8571429 Z"
            id="twitter-icon"
          ></path>
        </g>
      </g>
    </g>
  </SVG>
);

const SVG = styled.svg`
  #FC-design-system-atoms-icons {
    fill: #ffffffcc;
  }

  &:hover {
    #FC-design-system-atoms-icons {
      fill: #fff;
    }
  }
`;
