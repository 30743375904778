import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

class ModalUnit extends React.Component {
  constructor(props) {
    super(props);
    this.modalWindow = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    window.addEventListener('keyup', this.handleKeyUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    window.removeEventListener('keyup', this.handleKeyUp, false);
  }

  handleClick = e => {
    if (!this.modalWindow.current.contains(e.target)) {
      this.props.onClose();
    }
  };

  handleKeyUp = e => {
    if (e.keyCode === 27) {
      e.preventDefault();
      this.props.onClose();
    }
  };

  render() {
    return (
      <Backdrop>
        <Modal ref={this.modalWindow}>
          <CloseButton onClick={this.props.onClose}>
            <CloseIcon viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </CloseIcon>
          </CloseButton>
          {this.props.children}
        </Modal>
      </Backdrop>
    );
  }
}

ModalUnit.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default ModalUnit;

/**
 * STYLED - Dimmed background
 */
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background: rgba(0, 0, 0, 0.5);
`;

/**
 * STYLED - Actual modal window
 */
const Modal = styled.div`
  position: fixed;
  top: 10%;
  left: 5%;
  width: 90%;
  border-radius: 4px;
  background-clip: text !important;
  background-image: -webkit-linear-gradient(to top, #7f5692, #dc6e69) !important;
  background-image: linear-gradient(to top, #7f5692, #dc6e69) !important;
  padding: 1.5em 1.5em 1.5em 1.5em;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 500px) {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 40em;
    max-height: calc(100% - 1em);
  }
`;

/**
 * STYLED - Are for cross icon
 */
const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: none;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
`;

/**
 * STYLED - Cross icon
 */
const CloseIcon = styled.svg`
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: white;
  opacity: 0.6;
  stroke-linecap: round;
  stroke-width: 4;

  :hover {
    opacity: 0.3;
  }
`;
