import React from 'react';
import styled from 'styled-components';

export default (props) => (
  <SVG height="24" viewBox="0 0 43 24" fill="none" width="43" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="evenodd" transform="translate(.36838)">
      <path d="m23.8365314 12.0000202c0 6.6274226-5.3360328 11.9999798-11.9182657 11.9999798-6.58227335 0-11.9182657-5.3725572-11.9182657-11.9999798 0-6.62746304 5.33599235-12.0000202 11.9182657-12.0000202 6.5822329 0 11.9182657 5.37255716 11.9182657 12.0000202" />
      <path d="m36.9112655 12.0000202c0 6.2385723-2.6680569 11.2959351-5.9592948 11.2959351-3.2910759 0-5.9590923-5.0573628-5.9590923-11.2959351 0-6.23861268 2.6680164-11.2959755 5.9590923-11.2959755 3.2912379 0 5.9592948 5.05736282 5.9592948 11.2959755" />
      <path d="m42.2591222 12.0000202c0 5.5895165-.9382133 10.1206781-2.0958966 10.1206781-1.1572783 0-2.0958965-4.5311616-2.0958965-10.1206781 0-5.58955691.9386182-10.12071849 2.0958965-10.12071849 1.1576833 0 2.0958966 4.53116158 2.0958966 10.12071849" />
    </g>
  </SVG>
);

const SVG = styled.svg`
  fill: #ffffffcc;

  &:hover {
    fill: #fff;
  }
`;
