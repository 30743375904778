import CloseSimpleIconSVG from '../img/CloseSimpleIconSVG';
import React from 'react';
import styled from 'styled-components';

const Banner = class extends React.Component {
  state = {
    showBanner: true,
  };

  closeBanner = () =>
    this.setState({
      showBanner: false,
    });

  render() {
    return (
      <Row>
        {this.state.showBanner ? (
          <BannerDiv>
            <div className="text">
              <p className="bigcouch-company-link">
                FilmChain client platform is{' '}
                <span style={{ color: '#da6d6a' }}>now available!</span>
              </p>
            </div>
            <div className="button">
              <a href="/contact-us">CONTACT US FOR ACCESS</a>
            </div>
            <div className="close-icon" onClick={this.closeBanner}>
              <CloseSimpleIconSVG />
            </div>
          </BannerDiv>
        ) : null}
      </Row>
    );
  }
};

export default Banner;

const Row = styled.div`
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  @media screen and (min-width: 864px) {
    position: fixed;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
  }
`;
/**
 * STYLED - BannerRow
 */
const BannerDiv = styled.div`
  /* height: 100%; */
  /* height: 140px; */
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  row-gap: 0.75em;
  padding: 20px;
  padding-bottom: 26px;
  background: #151317;
  width: 100%;
  display: grid;
  /* justify-content: start; */
  /* align-items: center; */
  div.text {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    /* width: 208px; */
    p {
      /* white-space: nowrap; */
      color: #ffffff;
      font-family: 'Eina';
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      strong {
        text-decoration: underline;
      }
    }
  }

  div.close-icon {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    display: flex;
    justify-content: flex-end;
    svg {
      height: 18px;
      width: 18px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  div.button {
    height: 36px;
    border-radius: 4px;
    border-color: #434144;
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    background-color: #434144;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: block;
      text-transform: none;
      text-decoration: none;
      font-family: 'Eina';
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 0.4px;
      line-height: 16px;

      text-align: center;
      font-size: 12px;
    }
  }

  grid-template-rows: 44px 36px;
  grid-template-columns: minmax(218px, auto) 1fr;

  @media screen and (min-width: 630px) {
    height: 70px;
    min-width: 600px;
    max-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px 30px;

    div.button {
      margin-left: 25px;
      margin-right: 20px;
    }

    div.text {
      br {
        display: none;
      }
      width: auto;
      p {
        white-space: nowrap;
      }
    }
  }
`;
